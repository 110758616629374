<template>
  <section class="promo--list">
    <promo-card v-for="promo in promoList" :promo="promo" :key="promo.uuid" />
  </section>
</template>

<script>
import { mapState } from 'vuex';
import PromoCard from '@/components/promo/promo-card';

export default {
  components: { PromoCard },
  computed: {
    ...mapState({
      promoList: (state) => state.promo.promoList,
      copiedPromoCode: (state) => state.promo.copiedPromoCode,
    }),
  },
  methods: {
    copyCode(promoCode) {
      this.$store.commit('promo/SET_COPIED_PROMO_CODE', promoCode);
    },
  },
};
</script>
