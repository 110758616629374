<template>
  <default-layout>
    <div class="container promo--list-container">
      <banner :banner-text="bannerText" />
      <promos />
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Banner from '@/components/utils/page-banner';
import Promos from '@/components/promo/promos';

export default {
  name: 'promo',
  components: {
    DefaultLayout,
    Banner,
    Promos,
  },
  data: () => ({
    bannerText: 'Promo',
  }),
  async fetch({ store, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    await store.dispatch('promo/restoreInitialState');
    await store.dispatch('promo/getPromoList');
  },
};
</script>
