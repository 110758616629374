<template>
  <div class="promo--card">
    <router-link :to="`/promo/detail/${promo.uuid}`">
      <img :src="promo.web_image_url" class="image" />
      <div class="title--text">
        {{ promo.title }}
      </div>
      <div class="top--part" v-show="promo.end_date !== null">Periode: {{ period }}</div>
    </router-link>
    <div class="d-flex align-center" v-if="!isModal">
      <div class="left--col">
        <router-link :to="`/promo/detail/${promo.uuid}`">
          <div class="bottom--part">{{ $t('general.promo.code') }}: {{ promo.promo_code }}</div>
        </router-link>
      </div>
      <div class="right--col">
        <button v-if="!copied" class="btn btn-primary" @click="copyCode(promo.promo_code)">
          {{ $t('general.promo.copy') }}
        </button>
        <button v-else class="btn btn--green" @click="copyCode(promo.promo_code)">
          <i class="ion-checkmark mr-2"></i>
          <span>{{ $t('general.promo.copied') }}</span>
        </button>
      </div>
    </div>
    <div class="choose--promo" v-else>
      <button class="btn btn-primary" @click="$emit('choosePromo', promo.promo_code)">
        {{ $t('general.promo.use') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'promo-card',
  props: ['promo', 'isModal'],
  computed: {
    ...mapState({
      copiedPromoCode: (state) => state.promo.copiedPromoCode,
    }),
    copied() {
      if (!this.copiedPromoCode) return false;
      return this.copiedPromoCode === this.promo.promo_code;
    },
    period() {
      if (this.promo.end_date === null) return null;
      return `${this.$date.format(this.promo.start_date, 'dd MMM yyyy')} - ${this.$date.format(
        this.promo.end_date,
        'dd MMM yyyy',
      )}`;
    },
  },
  methods: {
    async copyCode() {
      this.$copyText(this.promo.promo_code);
      this.$store.commit('promo/SET_COPIED_PROMO_CODE', this.promo.promo_code);
    },
  },
};
</script>

<style scoped></style>
